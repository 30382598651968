import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useFeatureFlags } from '@core/context/FeatureFlagsContext';
import { selectDcsDegrees } from '@core/reducers/dcsSlice';
import { setInput, selectInput } from '@core/reducers/inputsSlice';
import { isUnderGraduate } from '@core/utils/isUnderGraduate';

import styles from './TcpaSection.module.css';

// Replace get info or submit info cta label within the tcpa text, return empty string if tcpa is not provided
const replaceCtaLabelInTcpa = (ctaLabel: string, tcpa?: string) =>
  tcpa ? tcpa.replace(/(submit|get) info|send your request/gi, ctaLabel) : '';

const TcpaSpan = ({ school }) => {
  const flags = useFeatureFlags();

  const [degree] = useSelector(selectDcsDegrees);

  const isGrad = !isUnderGraduate(degree);

  if (!school) return null;
  // Some schools, like 2U, have specfifc TCPA language that we need to use
  // Our cta button says Submit Info and some schools say Get Info
  const programFlow = school.programFlow?.find((item) => item.providerProgramIdArray.includes(school.hit?.program?.id));

  // get source of tcpa text
  const tcpa = programFlow?.tcpa ?? school?.tcpa;

  const isLearnMoreCta = isGrad || flags?.conversionCtaCopyUnderGrad === 'test';

  // get cta label (Grad conversion CTA test) fallback to Submit Info
  const ctaLabel = isLearnMoreCta ? 'Learn More' : 'Submit Info';

  const formattedTcpa: string = replaceCtaLabelInTcpa(ctaLabel, tcpa);

  return <span dangerouslySetInnerHTML={{ __html: formattedTcpa }} />;
};

const TcpaSection = ({ tcpa }) => {
  const dispatch = useDispatch();

  const hasGivenThirdPartySharingConsent = useSelector((state) =>
    selectInput(state, 'hasGivenThirdPartySharingConsent')
  );

  const hasGivenProspectSharingConsent = useSelector((state) => selectInput(state, 'hasGivenProspectSharingConsent'));

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setInput({ key: 'hasGivenThirdPartySharingConsent', value: event.target.checked }));
  };

  const onChangeProsectSharingConsent = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setInput({ key: 'hasGivenProspectSharingConsent', value: event.target.checked }));
  };

  useEffect(() => {
    // if hasGivenThirdPartySharingConsent and hasGivenThirdPartySharingConsent are
    // undefined (hasn't been answered) we set it's value to true

    if (hasGivenThirdPartySharingConsent === undefined) {
      dispatch(setInput({ key: 'hasGivenThirdPartySharingConsent', value: true }));
    }

    if (hasGivenProspectSharingConsent === undefined) {
      dispatch(setInput({ key: 'hasGivenProspectSharingConsent', value: true }));
    }
  }, []);

  if (!tcpa || !tcpa.length) return <></>;

  return (
    <section className={styles.tcpaSection}>
      <h4>Terms and Conditions</h4>
      {tcpa?.map((school) => (
        <div key={school.schoolId}>
          <b>{school.schoolName}:</b> &nbsp;
          <label htmlFor="leadid_tcpa_disclosure">
            <input type="hidden" id="leadid_tcpa_disclosure" />
            <TcpaSpan school={school} />
          </label>
          {school?.tcpaSecondParagraph &&
            (school?.schoolName.includes('Syracuse') ? (
              <p
                className={styles.tcpaSecondParagraph}
                dangerouslySetInnerHTML={{ __html: school?.tcpaSecondParagraph.split('\n')[0] }}
              />
            ) : (
              <p
                className={styles.tcpaSecondParagraph}
                dangerouslySetInnerHTML={{ __html: school?.tcpaSecondParagraph }}
              />
            ))}
          {school?.hit?.program?.providerName === 'TwoU' && (
            <label className={styles.checkbox} htmlFor="hasGivenThirdPartySharingConsent">
              <input
                checked={hasGivenThirdPartySharingConsent}
                onChange={onChange}
                type="checkbox"
                id="hasGivenThirdPartySharingConsent"
              />
              <span>Please contact me about these educational programs.</span>
            </label>
          )}
          {school?.schoolName.includes('Syracuse') && (
            <label className={styles.checkbox} htmlFor="hasGivenProspectSharingConsent">
              <input
                checked={hasGivenProspectSharingConsent}
                onChange={onChangeProsectSharingConsent}
                type="checkbox"
                id="hasGivenProspectSharingConsent"
              />
              <span dangerouslySetInnerHTML={{ __html: school?.tcpaSecondParagraph.split('\n')[2] }} />
            </label>
          )}
        </div>
      ))}
    </section>
  );
};
export default TcpaSection;
