import { type FC } from 'react';
import { HiCheckCircle } from 'react-icons/hi2';

import cn from '@core/utils/classNames';

import styles from './LearnMoreHeaderAndSubheader.module.css';

type Props = {
  biggerFontAndSpacing?: boolean;
};

const LearnMoreHeaderAndSubheader: FC<Props> = ({ biggerFontAndSpacing }) => (
  <section className={styles.learnMoreConversionCtaTest}>
    <span className={styles.learnMoreConversionCtaTestHeader}>
      <HiCheckCircle size={32} />
      <h1>Want to learn more?</h1>
    </span>
    <p className={cn(styles.learnMoreConversionCtaTestContent, biggerFontAndSpacing && styles.biggerFontAndSpacing)}>
      Submit your contact information to show this school you&apos;re interested! You can even start your application on
      the next screen.
    </p>
  </section>
);

export default LearnMoreHeaderAndSubheader;
