import { BaseUiSchemaKeys } from '@core/shared/features/PostResultForm/types';
import { Cohesion } from '@core/ts/cohesion';

import Lead, { Location } from '../ts/leadDelivery';
import { PageActions, newRelicCustomAttribute, newRelicNoticeError, nrErrorMap } from './newRelic';

export type ContactRequest = {
  degree: string;
  subject: string;
  category: string;
  firstName: Lead['firstName'];
  lastName: Lead['lastName'];
  email: Lead['email'];
  phoneNumber: Lead['phoneNumber'];
  zip: Location['postalCode'];
  details: Lead;
  cohesionSessionId: string;
  trackingContext?: Cohesion['webContext'] & {
    publisher: string;
  };
};

export type SaveContactParams = {
  degree: ContactRequest['degree'];
  subject: ContactRequest['subject'];
  category: ContactRequest['category'];
  cohesionSessionId: ContactRequest['cohesionSessionId'];
  lead: ContactRequest['details'];
  publisher: string;
};

export type SaveContactApiResponseSuccess = {
  contact: {
    contactId: string;
  };
};

export type SaveContactApiResponseFailure = {
  code: string;
  error: string;
  path: string;
};

export type EnhancedSchoolData = {
  sdbId: string;
  schoolId: number;
  schoolName: string;
  startDate: string;
  timeToComplete: string;
  tuitionRange: string;
  averageFederalGrantAidAwarded: string;
  acceptanceRate: string;
  graduationRate: string;
};

export type EnhancedSchoolDataResponse = { data?: EnhancedSchoolData[] };

export type SaveContactResult = {
  success: boolean;
  data?: SaveContactApiResponseSuccess['contact'];
  error?: SaveContactApiResponseFailure;
};

function sendRequest<S, R = unknown>(path: string, body?: R): Promise<S> {
  return fetch(`${import.meta.env.VITE_HUB_API_BASE_URL}${path}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${import.meta.env.VITE_HUB_API_KEY}`,
    },
    body: JSON.stringify(body ?? {}),
    method: 'POST',
  }).then((res) => res.json()) as Promise<S>;
}

export async function saveContact(data: SaveContactParams): Promise<SaveContactResult> {
  const body: ContactRequest = {
    degree: data.degree,
    subject: data.subject,
    category: data.category,
    firstName: data.lead.firstName?.trim(),
    lastName: data.lead.lastName?.trim(),
    email: data.lead.email?.trim(),
    phoneNumber: data.lead.phoneNumber,
    zip: data.lead.location?.postalCode,
    details: data.lead,
    cohesionSessionId: data.cohesionSessionId,
    trackingContext: {
      ...window._Cohesion?.webContext,
      publisher: data.publisher || window.HE?.publisher?.name || window.location.host,
    },
  };

  try {
    const response = await sendRequest<SaveContactApiResponseSuccess & SaveContactApiResponseFailure, ContactRequest>(
      'api/rest/v1/prospect/contact',
      body
    );

    if (response.error?.length) {
      newRelicNoticeError(nrErrorMap.CONTACT_DB, new Error(response.error), response);
      return { success: false, error: response };
    }

    if (!response.contact?.contactId?.length) {
      newRelicNoticeError(nrErrorMap.CONTACT_DB, new Error('invalid contactId returned from contactDb'), {
        contactId: response.contact?.contactId,
      });
      return { success: false, error: response };
    }

    newRelicCustomAttribute('contactId', response.contact?.contactId);
    PageActions.ContactSaved({ contactId: response.contact?.contactId });

    return { success: true, data: response.contact };
  } catch (e) {
    newRelicNoticeError(nrErrorMap.CONTACT_DB, new Error((e as SaveContactApiResponseFailure).error));
    return { success: false, error: e as SaveContactApiResponseFailure };
  }
}

export async function fetchEnhancedSchoolData(): Promise<EnhancedSchoolData[]> {
  return sendRequest<EnhancedSchoolDataResponse>('api/rest/v1/school/partner-verified-school-data').then(
    (res) => res.data as EnhancedSchoolData[]
  );
}

export type ProgramFormFieldOptions = {
  label: string;
  value: unknown;
};

export type ProgramFormFieldsConfig =
  | {
      [key in BaseUiSchemaKeys]: {
        question: string;
        options: ProgramFormFieldOptions[];
      };
    }
  | null;

export type ProgramFormFieldsConfigResponse = { getProgramFormFieldsConfig: ProgramFormFieldsConfig };

export async function fetchProgramFormFieldConfig(programId: number): Promise<ProgramFormFieldsConfig> {
  const response = await sendRequest<ProgramFormFieldsConfigResponse>('api/rest/v1/program/form-fields-config', {
    programId,
  });

  return response?.getProgramFormFieldsConfig as ProgramFormFieldsConfig;
}
