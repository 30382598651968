import { FC } from 'react';

import SponsoredResultsPopover from '@core/shared/components/SponsoredResultsPopover';
import { VoyagerResult, VoyagerResultEnhancedSchoolData } from '@core/ts/results';
import cn from '@core/utils/classNames';

import Card from '../Card';
import styles from './ResultsList.module.css';

type Props = {
  header: string;
  subheader: string;
  results: VoyagerResult[];
  borrowedResults?: VoyagerResult[] | null;
  isExactMatch?: boolean;
  enhancedSchoolData?: VoyagerResultEnhancedSchoolData;
  showAdVerbiageTest?: boolean;
  showUgToGradResultsPageABTest?: boolean;
};
const ResultsList: FC<Props> = ({
  enhancedSchoolData,
  header,
  subheader,
  results,
  isExactMatch,
  borrowedResults,
  showAdVerbiageTest,
  showUgToGradResultsPageABTest,
}) => (
  <div className={styles.matchListContainer}>
    <div className={styles.matchListHeader}>
      <h2>{header}</h2>

      <div className={styles.sponsored}>
        <SponsoredResultsPopover
          spanText={showAdVerbiageTest || showUgToGradResultsPageABTest ? 'Learn More About Our Partners' : 'Sponsored'}
        />
      </div>
    </div>

    <p className={styles.matchListSubheader}>{subheader}</p>

    <div className={styles.sponsoredMobile}>
      <SponsoredResultsPopover
        spanText={showAdVerbiageTest || showUgToGradResultsPageABTest ? 'Learn More About Our Partners' : 'Sponsored'}
      />
    </div>

    <div className={cn(styles.resultsGrid, showUgToGradResultsPageABTest ? styles.threeColumns : styles.twoColums)}>
      {results?.map((result, index) => (
        <Card
          ctaLabel="Explore Program"
          key={result.program.id}
          result={result}
          enhancedSchoolData={enhancedSchoolData?.[result.school.sdbSchoolId]}
          eventingOverrides={{
            location: isExactMatch ? 'voyager-results-page' : 'voyager-related-results-page',
            customDimensions: [
              {
                key: 'isUserRequested',
                value: String(isExactMatch),
              },
            ],
          }}
          position={index + 1}
        />
      ))}
      {borrowedResults?.map((result, index) => (
        <Card
          ctaLabel="Explore Program"
          enhancedSchoolData={enhancedSchoolData?.[result.school.sdbSchoolId]}
          key={result.program.id}
          result={result}
          eventingOverrides={{
            location: 'voyager-results-page',
            customDimensions: [
              {
                key: 'isUserRequested',
                value: 'false',
              },
            ],
          }}
          position={index + 1}
        />
      ))}
    </div>
  </div>
);

ResultsList.defaultProps = {
  borrowedResults: null,
  isExactMatch: false,
};
export default ResultsList;
