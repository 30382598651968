import { type IdSchema } from '@rjsf/utils';
import { type FC } from 'react';

import { type VoyagerInputs } from '@core/reducers/inputsSlice';
import type { VoyagerResult } from '@core/ts/results';

import type { ApplicationCta, UserPreferencesAndMilitary } from '../../types';
import DrawerExperienceHeader from '../DrawerExperienceHeader';
import ProgramInfoTabs from '../ProgramInfoTabs';
import ProgramSubmissionFlow from '../ProgramSubmissionFlow';
import { FlowType } from '../ProgramSubmissionFlow/types';
import { StepTypes } from '../Steps/types';
import styles from './DrawerExperience.module.css';

type Props = {
  result: VoyagerResult;
  onFormSubmit: (result: VoyagerResult) => Promise<number | undefined>;
  prqSchema?: IdSchema;
  relatedSchoolResults: VoyagerResult[];
  userInputs: VoyagerInputs;
  category: string;
  onProgramCtaClick: (result: VoyagerResult) => void;
  onEdit: (result: VoyagerResult) => void;
  onFlowExit: () => void;
  publisher: string;
  hasCurrentProgramBeenSubmitted: boolean;
  currentResultApplicationCta: ApplicationCta;
  isHeclidLoading: boolean;
  isCurrentResultApplicationCtaLoading: boolean;
  userPreferencesAndMilitary: UserPreferencesAndMilitary;
};

const DrawerExperience: FC<Props> = ({
  result,
  onFormSubmit,
  relatedSchoolResults,
  prqSchema,
  userInputs,
  onProgramCtaClick,
  onFlowExit,
  onEdit,
  category,
  publisher,
  hasCurrentProgramBeenSubmitted,
  currentResultApplicationCta,
  isHeclidLoading,
  isCurrentResultApplicationCtaLoading,
  userPreferencesAndMilitary,
}) => {
  const lastPreference = userPreferencesAndMilitary[userPreferencesAndMilitary.length - 2].label ?? 'Affordability';
  return (
    <div className={styles.submissionContainer}>
      <div className={styles.mobileHeader}>
        <DrawerExperienceHeader result={result} userPreferencesAndMilitary={userPreferencesAndMilitary} />
      </div>
      <div className={styles.programInfoSection}>
        <div className={styles.desktopHeader}>
          <DrawerExperienceHeader result={result} userPreferencesAndMilitary={userPreferencesAndMilitary} />
        </div>

        <div className={styles.programTabsDesktop}>
          <ProgramInfoTabs
            showWhatsNextTab={hasCurrentProgramBeenSubmitted}
            result={result}
            relatedSchoolResults={relatedSchoolResults}
            category={category}
            lastUserPreference={lastPreference}
          />
        </div>
      </div>

      <div className={styles.programSubmissionFlowContainer}>
        <ProgramSubmissionFlow
          isHeclidLoading={isHeclidLoading}
          prqSchema={prqSchema}
          result={result}
          onFormSubmit={onFormSubmit}
          userInputs={userInputs}
          onFlowExit={onFlowExit}
          onProgramCtaClick={onProgramCtaClick}
          initialStep={StepTypes.PII_CONFIRMATION}
          flowType={FlowType.DRAWER}
          onEdit={onEdit}
          publisher={publisher}
          currentResultApplicationCta={currentResultApplicationCta}
          isCurrentResultApplicationCtaLoading={isCurrentResultApplicationCtaLoading}
          hasCurrentProgramBeenSubmitted={hasCurrentProgramBeenSubmitted}
          userPreferencesAndMilitary={userPreferencesAndMilitary}
        />
      </div>
    </div>
  );
};

export default DrawerExperience;
