import { isDevelopment } from '@core/blocks/edu-match/utils/determineEnv';
import { type Flags } from '@core/context/FeatureFlagsContext';
import type { MonarchEditableAsset } from '@core/context/types';
import { qualifyEduRequest } from '@core/services/leadDelivery';

import { type InjectionKey } from './getQuestionInjectionSchema';
import { type GCPParams, generateQualifyPayload, getQualifiedPartnerKeys } from './utils/qualifications';

type GNCPSParams = {
  flags: Flags;
} & GCPParams;

export const isEditableAsset = (asset?: MonarchEditableAsset | false | 'conditions_not_met') =>
  asset && asset !== 'conditions_not_met';

const getInjectionKeys = async ({
  dcs,
  ipInferredPostalCode,
  flags,
  publisher,
}: GNCPSParams): Promise<InjectionKey[]> => {
  const qualifyPayload = generateQualifyPayload({ dcs, ipInferredPostalCode, publisher });

  const res = await qualifyEduRequest(qualifyPayload);

  if (res instanceof Error) {
    return [];
  }

  let injectionKeys = getQualifiedPartnerKeys(res.qualified, flags);

  if (isEditableAsset(flags?.personalizationQuestionPursuingHigherEducation)) {
    injectionKeys.push('PURSUING_HIGHER_EDUCATION');
  }

  if (isEditableAsset(flags?.personalizationQuestionImportantFactors)) {
    injectionKeys.push('IMPORTANT_FACTORS');
  }

  // Hardcoded rules
  if (injectionKeys.includes('EMCC')) {
    // Remove EAB keys if EMCC is present
    injectionKeys = injectionKeys.filter((key) => !key.includes('EAB'));
  }

  if (isDevelopment()) {
    console.log('Flags:', flags);
    console.log('Injection Keys:', injectionKeys);
  }
  return injectionKeys;
};

export default getInjectionKeys;
