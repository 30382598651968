import { type Flags } from '@core/context/FeatureFlagsContext';
import { MonarchEditableAsset } from '@core/context/types';
import { type BlockPage } from '@core/schemas/schema';

export type QuestionInjectionConfig = { insertPosition?: string; replacePosition?: string; pages: BlockPage[] };
export type InjectionKey = 'EMCC' | 'EAB_GRAD' | 'EAB_UNDERGRAD' | 'IMPORTANT_FACTORS' | 'PURSUING_HIGHER_EDUCATION';

export type QuestionInjectionSchema = {
  [key in InjectionKey]?: QuestionInjectionConfig[];
};

const getQuestionInjectionSchema = (flags: Flags): QuestionInjectionSchema => ({
  IMPORTANT_FACTORS: [
    {
      insertPosition: '/level-of-education',
      pages: [
        {
          slug:
            (flags?.personalizationQuestionImportantFactors as MonarchEditableAsset).questionSlug ??
            '/important-factors',
          stepNumber: 1.5,
          questionId: 'editable-asset-personalization-important-factors',
        },
      ],
    },
  ],
  PURSUING_HIGHER_EDUCATION: [
    {
      insertPosition: '/level-of-education',
      pages: [
        {
          slug:
            (flags?.personalizationQuestionPursuingHigherEducation as MonarchEditableAsset).questionSlug ??
            '/pursuing-higher-education',
          stepNumber: 1.5,
          questionId: 'editable-asset-personalization-pursuing-higher-education',
        },
      ],
    },
  ],
  EMCC: [
    {
      replacePosition: '/name',
      pages: [
        {
          slug: '/name',
          stepNumber: 5.5,
          questionId: '1004-name-emcc-test',
        },
        {
          slug: '/contact-info-emcc',
          stepNumber: 5.6,
          questionId: '1032-email-phone-emcc',
          overrideNextStep: '/results',
        },
      ],
    },
    {
      replacePosition: '/zip',
      pages: [
        {
          slug: '/zip',
          stepNumber: 4.6,
          questionId: '1003-zip-code-emcc-test',
        },
      ],
    },
  ],
  EAB_UNDERGRAD: [
    {
      insertPosition: '/level-of-education',
      pages: [
        {
          slug: '/transfer-student',
          stepNumber: 1.5,
          questionId: '1031-transfer-student',
        },
      ],
    },
    {
      replacePosition: '/zip',
      pages: [
        {
          slug: '/zip',
          stepNumber: 4.6,
          questionId: '1003-zip-code-eab-test',
        },
      ],
    },
    {
      replacePosition: '/name',
      pages: [
        {
          slug: '/name',
          stepNumber: 5.5,
          questionId: '1004-name-eab-test',
        },
        {
          slug: '/contact-info-eab',
          stepNumber: 5.5,
          questionId: '1027-email-phone-is-eab-undergrad',
          overrideNextStep: '/results',
        },
      ],
    },
  ],
  EAB_GRAD: [
    {
      replacePosition: '/zip',
      pages: [
        {
          slug: '/zip',
          stepNumber: 4.6,
          questionId: '1003-zip-code-eab-test',
        },
      ],
    },
    {
      replacePosition: '/name',
      pages: [
        {
          slug: '/name',
          stepNumber: 5.5,
          questionId: '1004-name-eab-test',
        },
        {
          slug: '/contact-info-eab',
          stepNumber: 5.5,
          questionId: '1027-email-phone-is-eab-grad',
          overrideNextStep: '/results',
        },
      ],
    },
  ],
});
export default getQuestionInjectionSchema;
