import { Dispatch, FC, SetStateAction } from 'react';
import { FaRegClock } from 'react-icons/fa';

import { SchoolConfigData } from '@core/blocks/edu-match/components/ExitStrategy/type';
import { useFeatureFlags } from '@core/context/FeatureFlagsContext';
import LearnMoreHeaderAndSubheader from '@core/shared/components/LearnMoreHeaderAndSubheader';
import PulsingDotLoader from '@core/shared/components/PulsingDotLoader';
import TcpaSection from '@core/shared/features/TcpaSection';
import { VoyagerResult } from '@core/ts/results';
import cn from '@core/utils/classNames';

import AccordionItem from '../AccordionItem';
import ConfirmationRevamp from '../ConfirmationRevamp';
import styles from './PreSubmissionPageRevamp.module.css';

type Props = {
  result: VoyagerResult;
  schoolConfigData: SchoolConfigData;
  handleSubmit: () => void;
  setFirstName: Dispatch<SetStateAction<string>>;
  setLastName: Dispatch<SetStateAction<string>>;
  setEmail: Dispatch<SetStateAction<string>>;
  setPhone: Dispatch<SetStateAction<string>>;
  piiLeadErrors: { field: string }[];
  isHeclidReady: boolean;
  isSubmitting: boolean;
};

const PreSubmissionPageRevamp: FC<Props> = ({
  result,
  schoolConfigData,
  handleSubmit,
  setFirstName,
  setLastName,
  setEmail,
  setPhone,
  piiLeadErrors,
  isHeclidReady,
  isSubmitting,
}) => {
  const flags = useFeatureFlags();
  const { school, program } = result;
  const tcpa = { ...schoolConfigData, providerName: program?.providerName };

  const isUGToGradResultsPageABTest = flags?.voyagerGradResultsPage === 'test';
  const isConversionCtaTest = flags?.conversionCtaCopyUnderGrad === 'test';
  const ctaLabel = isConversionCtaTest || isUGToGradResultsPageABTest ? 'Learn More' : 'Confirm & Submit';
  const buttonText = isSubmitting || !isHeclidReady ? <PulsingDotLoader /> : ctaLabel;

  return (
    <>
      <div
        className={cn(
          styles.preSubContainer,
          isConversionCtaTest && styles.gradRpTestBg,
          isUGToGradResultsPageABTest && styles.ugToGradTest
        )}
      >
        <div className={styles.highlightsContainer}>
          {(isConversionCtaTest || isUGToGradResultsPageABTest) && <LearnMoreHeaderAndSubheader />}

          {!isUGToGradResultsPageABTest && (
            <>
              <span className={cn(styles.highlightsProgram, isConversionCtaTest && styles.gradRpTestProgramName)}>
                {program.name}
              </span>
              <h2 className={cn(styles.highlightsTitle, isConversionCtaTest && styles.gradRpTestHighlightTitle)}>
                School Highlights
              </h2>
              <div className={styles.flagsContainer}>
                {schoolConfigData.showNextStartDate && (
                  <p className={styles.acceptingStudentsCta}>
                    <FaRegClock size="20px" /> Now Accepting Students
                  </p>
                )}

                <ul className={styles.flagsList}>
                  <li>{school?.additional?.isPrivate ? <>Private</> : <>Public</>}</li>
                  <li>{school?.additional?.isOnline ? <>Online</> : <>Oncampus</>}</li>
                  <li>{school?.additional?.notForProfit ? <>Nonprofit</> : <>Forprofit</>}</li>
                </ul>
              </div>
              <div className={styles.highlightsContent}>
                <p className={styles.highlightsContentIntro}>{program.snippet}</p>
                <div className={styles.highlightsList}>
                  {!isUGToGradResultsPageABTest &&
                    schoolConfigData.valueProps.map(
                      (highlight: { title: string; description: string }, index: number) => (
                        <AccordionItem title={highlight.title} description={highlight.description} index={index} />
                      )
                    )}
                </div>
              </div>
            </>
          )}
        </div>
        <div className={styles.submissionSidebar}>
          <span className={cn(styles.highlightsProgramMobile, isConversionCtaTest && styles.gradRpTestProgramName)}>
            {program.name}
          </span>
          <h3 className={styles.confirmDetailsTitle}>Confirm your contact details:</h3>
          <ConfirmationRevamp
            setFirstName={setFirstName}
            setLastName={setLastName}
            setEmail={setEmail}
            setPhone={setPhone}
            piiLeadErrors={piiLeadErrors}
          />
          <button
            className={styles.confirmationButton}
            type="submit"
            disabled={!isHeclidReady || isSubmitting}
            onClick={handleSubmit}
          >
            {buttonText}
          </button>
          <div className={styles.disclaimers}>
            <h2>Your information will never be shared with a school without your consent.</h2>
            <TcpaSection tcpa={tcpa ? [tcpa] : null} />
          </div>
          <div className={styles.mobileHighlightsContainer}>
            {isUGToGradResultsPageABTest && <LearnMoreHeaderAndSubheader />}
          </div>
        </div>
      </div>
    </>
  );
};

export default PreSubmissionPageRevamp;
