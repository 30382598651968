import { type FC } from 'react';
import { BsCheckCircleFill, BsXCircleFill } from 'react-icons/bs';

import { type Option, type Field } from '@core/blocks/edu-flow-2/components/Fields/types';
import { type VoyagerInput } from '@core/reducers/inputsSlice';
import cn from '@core/utils/classNames';
import convertToSlug from '@core/utils/strings/convertToSlug';

import styles from './OptionGroupOptions.module.css';

const iconMap = {
  yes: <BsCheckCircleFill className={styles.svgIcon} />,
  no: <BsXCircleFill className={styles.svgIcon} />,
};

type Props = {
  field: Field;
  handleSelection: (option: Option) => void;
  fieldInputObject?: VoyagerInput;
  isSelected: (selection: VoyagerInput, option: Option) => boolean;
};

const OptionGroupOptions: FC<Props> = ({ field, handleSelection, fieldInputObject, isSelected }) => (
  <div
    data-testid="option-group"
    className={cn(styles.checkboxGroup, styles[field.width as keyof typeof field.width] ?? styles.xs)}
  >
    {(field.options as Option[])?.map((value, index) =>
      field.mode !== 'yesorno' ? (
        <label
          key={index}
          onChange={() => handleSelection(value)}
          htmlFor={`option-${index}`}
          className={cn(
            styles.checkboxContainer,
            isSelected(fieldInputObject as VoyagerInput, value) && styles.selected
          )}
          data-testid={convertToSlug(`${field.name} ${value.value}`)}
        >
          {value.label}
          <input id={`option-${index}`} type="checkbox" className={styles.hiddenCheckbox} />
        </label>
      ) : (
        <label
          key={value.label}
          onChange={() => handleSelection(value)}
          className={cn(
            styles.checkboxContainer,
            styles.yesOrNo,
            isSelected(fieldInputObject as VoyagerInput, value) && styles.selected
          )}
          htmlFor={value.label}
          data-testid={convertToSlug(`${field.name} ${value.value}`)}
        >
          <input id={value.label} type="checkbox" className={styles.hiddenCheckbox} />
          <div className={styles.labelContent}>
            {iconMap[value.icon as keyof typeof iconMap]}
            <span className={styles.labelText}>{value.label}</span>
          </div>
        </label>
      )
    )}
  </div>
);

export default OptionGroupOptions;
