import React from 'react';

import { type AdditionalContentField } from '@core/blocks/edu-flow-2/components/AdditionalContentFields/types';
import { type FlagKey } from '@core/context/FeatureFlagsContext';
import { type StepContext } from '@core/ts/cohesion';

import { type ConditionalType } from '../Fields/ConditionalField/types';
import { type Field } from '../Fields/types';
import { type DefaultPageProps } from './DefaultPage';

export type stepContext = {
  stepId: string;
  stepName: string;
  stepNumber: number;
};
type Eventing = {
  stepContext?: stepContext;
};
export type ConditionalContinue = {
  name: string;
  operator: string;
  action: string;
  rules: {
    field: string;
    type: string;
    value?: any;
  }[];
}[];
type Content = {
  fields?: Object[];
  heading?: string;
  type?: string;
};
type Logic = {
  operator?: string;
  fields: Object[];
};
export type ConditionalRoute = {
  name?: string;
  value?: string;
  path?: string;
};

export type PageType = {
  slug: string;
  questionId: string;
  stepNumber: number;
  eventing?: Eventing;
  overrideNextStep?: string;
};

export type QuestionType = {
  stepName: string;
  stepId: string;
  heading: string;
  extends?: string;
  monarchEditableAssetFlag?: FlagKey;
  preSubheading?: string;
  postSubheading?: string;
  description?: string;
  showNextQuestionButton?: boolean;
  hideProgressBarAndBackButton?: boolean;
  hideSidebar?: boolean;
  nextQuestionButtonLabel?: string;
  showContinueButton?: boolean;
  showSelectedProgram?: boolean;
  disclaimer: string;
  conditionalContinue: ConditionalContinue;
  additionalContent?: AdditionalContentField[];
  fields: any[];
  conditionalRoutes?: ConditionalRoute[];
  conditional?: {
    content: {
      fields: Array<Field>;
    };
  };
};

export type PageProps = {
  flow: PageType[];
  page: PageType;
  PageComponent?: React.FC<DefaultPageProps>;
  index: number;
  hasUpdatedStepContext: boolean;
};

export enum Degrees {
  ASSOCIATES = "Associate's",
  BACHELORS = "Bachelor's",
  MASTERS = "Master's",
  DOCTORATE = 'Doctorate',
  CERTIFICATE = 'Certificate',
  DIPLOMA = 'Diploma',
  BOOTCAMPS = 'Bootcamps',
}
